.logged-in {
    .site-header {
        left: 45px;
    }
    .js-editable-video.editable__thumbnail {
        width: 100%!important;
    }
    .editable.editable--iframeembed {
        width: 100%;

        iframe {
            width: 100%;
            min-height: 500px;
        }
    }
    .grid-article.trim.latest-news + .ex-section-buttons {
        display: none;
    }
    .editable{

        &.editable--image {
            width: 100%;

            .editable__bounding-box {

                &.js-editable-bound {
                    width: 100%;
                }
            }
        }
    }
    .opening-times {
        display: flex!important;
    }
    .ex-home-grid-admin{
        .ex-section-buttons {
            background: none;
            bottom: auto;
            left: auto;
            z-index: 10;
        }
        .grid-image {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
        }

        .editable:not(.is-editing):before{
            top: 1px;
            right: 1px;
        }
        .editable:not(.is-editing):after{
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
        }
        .grid-item > *{
            overflow: hidden;
        }
    }
    .img {
        overflow: visible;
        &:before{
            display: none;
        }
        img {
            position: relative;
        }
    }
    .slider-item__image{
        &:before{
            padding-top: 0;
        }
        img{
            position: relative;
        }
    }

    .open-24-hours-btn {
        display: block;
        width: 100%;
        flex: inherit;

        button {
            padding: 6px 10px;
            margin: 10px auto;
            display: block;
            text-transform: uppercase;
            background: $color-manatee;
            border-radius: 3px;
            color: #fff;
            border: none;
            font-size: 11px;
        }
    }

    .opening-times {
        position: relative;

        &__admin {
            position: relative;
            width: 100%;
            height: auto;
        }


        .admin-edit-day {
            position: absolute;
            top: 0;
            left: 260px;
            background: #414154;
            padding: 5px;
            width: 300px;

            label {
                width: 33%;
                display: inline-block;
                float: left;
                text-align: center;

                input.js-closed-for-today {
                    display: block;
                    margin: 0 auto;
                }

                .open-24-hours-btn {

                    input[type=checkbox] {
                        display: block;
                        margin: 0 auto;
                    }

                }

            }

        }

    }
}

.card__main{
    .ex-notice{
        margin: 20px 0;
    }
}
