// .nav-list {
//   display: none !important;
// }

.nav {
    height: 90px;
    white-space: nowrap;
  @media (max-width: 699px) {
    margin-left: auto;
  }
  @media (max-width: 999px) {
    height: 75px;
  }
  @media (max-width: $mobile-nav - 1px) {
    margin-left: auto;
  }
}

.nav-toggle {
  position: relative;
  z-index: 103;
  display: flex;
  align-items: center;
  height: 90px;
  color: #fff;
  text-decoration: none;
  background: $color-teal;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  padding: 0 (32.5px + 25px + 11.5px) 0 32.5px;

  @media (max-width: 999px) {
    height: 75px;
    padding: 0 (29px + 17px + 11.5px) 0 29px;
  }

  @media (min-width: $mobile-nav) {
    display: none;
  }

  @include nav-open {
    background: $color-teal;
    width: 90px;
    padding: 0;

    @media (max-width: 999px) {
      width: 75px;
    }

    @media (max-width: 699px) {
      box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

.nav-toggle__text {
  @include nav-open {
    visibility: hidden;
  }
}

.nav-toggle::before,
.nav-toggle::after,
.nav-toggle span:not(.nav-toggle__text) {
  content: '';
  display: block;
  width: 25px;
  height: 3px;
  background: rgba(#fff, 0.5);
  border-radius: 2px;
  position: absolute;
  top: 50%;
  right: 32.5px;

  @media (max-width: 999px) {
    width: 17px;
    height: 2px;
    right: 29px;
  }

  @include nav-open {
    background: #fff;
  }
}

.nav-toggle span:not(.nav-toggle__text) {
  margin-top: -1px;

  @include nav-open {
    opacity: 0;
  }
}

.nav-toggle::before {
  margin-top: -9px;

  @media (max-width: 999px) {
    margin-top: -7px;
  }

  @include nav-open {
    transform: translateY(8px) rotate(45deg);

    @media (max-width: 999px) {
      transform: translateY(6px) rotate(45deg);
    }
  }
}

.nav-toggle::after {
  margin-top: 7px;

  @media (max-width: 999px) {
    margin-top: 5px;
  }

  @include nav-open {
    transform: translateY(-8px) rotate(-45deg);

    @media (max-width: 999px) {
      transform: translateY(-6px) rotate(-45deg);
    }
  }
}

.nav-toggle:hover,
.nav-toggle:focus {
  outline: 0;
  background: lighten($color-teal, 5%);

  &::before,
  &::after,
  span:not(.nav-toggle__text) {
    background: #fff;
  }
}


.nav-list {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  font-weight: bold;

  display: none;

  @include nav-open {
    display: flex;
  }

  @media (min-width: $mobile-nav) {
    top: 0;
    bottom: 0;
    left: (50px + 164px + 33px);
    right: 0;
    background: #fff;
    font-size: 1.6rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (min-width: 1280px) {
    font-size: 1.8rem;
    right: (50px + 90px);
    justify-content: center;
  }

  @media (max-width: 999px) {
    left: 162px;
  }

  @media (max-width: $mobile-nav - 1px) {
    position: absolute;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    top: 75px;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-indigo;
    color: #fff;
    flex-direction: column;
    padding: 18px 0;
    font-size: 1.6rem;

    .site-header__upcoming + .nav & > li:last-child {
        margin-bottom: (90px + 18px);
    }
  }

  // @media (max-width: 999px) {
  //   top: 75px;
  // }
}

.nav-list a {
  color: inherit;
  text-decoration: none;
  display: block;

  @media (min-width: $mobile-nav) {
    height: 90px;
    line-height: 90px;
    padding-left: 30px;
    padding-right: 30px;

    &.is-current {
        color: $color-bali-hai;
    }
  }

  @media (max-width: 999px) {
    padding-left: 19px;
    padding-right: 19px;
  }

  @media (max-width: $mobile-nav - 1px) {
    padding: 10px 25px;
  }
}

.nav__link {
  position: relative;
}

.nav-list > li {
    @media (max-width: $mobile-nav - 1px) {
        position: relative;
    }
}

.nav__expand {
  @media (min-width: $mobile-nav) {
    display: none;
  }

  appearance: none;
  background: none;
  border-radius: 0;
  border: 0;
  padding: 0;
  width: (32px + 10px + 32px);
  height: calc(1em + 20px);

  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    background: $color-manatee;
    top: 50%;
    border-radius: 1px;
  }

  &::before {
    width: 2px;
    height: 10px;
    margin-top: -5px;
    right: 36px;

    .is-active & {
      display: none;
    }
  }

  &::after {
    width: 10px;
    height: 2px;
    margin-top: -1px;
    right: 32px;
  }
}


.sub-nav {
  display: none;

  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  color: #b3b3ba;
  font-weight: normal;
  letter-spacing: normal;

  .is-active & {
    @media (max-width: $mobile-nav - 1px) {
      display: block;
    }
  }
}

.sub-nav svg {
  fill: $color-manatee;
  position: relative;
  top: 0.13em;
  margin-right: 12px;
}

.sub-nav a {
  &:hover,
  &:focus {
    outline: 0;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}
