.site-header {
  height: 92px;
  border-bottom: 2px solid #f6f9fd;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;

  @include nav-open {
    @media (max-width: $mobile-nav - 1px) {
      bottom: 0;
      height: auto !important;
    }
  }

  @media (max-width: 999px) {
    height: 77px;
  }
}

.site-header + * {
  margin-top: 92px;

  @media (max-width: 999px) {
    margin-top: 77px;
  }
}

.site-header .container {
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 999px) {
    padding-right: 0;
  }

  @media (max-width: $mobile-nav - 1px) {
    position: static;
  }
}

.site-header__logo {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 164px + 33px;
  padding: 0 33px 0 0;

  @media (max-width: 999px) {
    width: 132px;
    padding-right: 20px;
  }

  @media (max-width: 799px) {
    width: 162px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 90px;
    transform: translateY(-50%);
    background: #f6f9fd;
    width: 2px;

    @media (max-width: 999px) {
      height: 75px;
    }

    @media (max-width: 699px) {
      display: none;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    @media (max-width: 799px) {
      margin-top: 6px;
    }
  }
}

.site-header__highlight {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  height: 5px;
  background: $color-teal;
  width: (50px + 195px);
  transform-origin: left;
  transition: transform 0.3s;

  @media (max-width: 999px) {
    width: 160px;
  }

  @media (max-width: $mobile-nav - 1px) {
    display: none;
  }
}
