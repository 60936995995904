@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/larsseit-regular.woff2') format('woff2'), url('../fonts/larsseit-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/larsseit-regular-italic.woff2') format('woff2'), url('../fonts/larsseit-regular-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/larsseit-bold.woff2') format('woff2'), url('../fonts/larsseit-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/larsseit-bold-italic.woff2') format('woff2'), url('../fonts/larsseit-bold-italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Noe Display';
    src: url('../fonts/noe-display-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
