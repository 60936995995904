.tenant-list {
  list-style: none;
  margin: -24px -12px 0;
  padding: 0;
  text-align: center;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: 499px) {
    margin: -12px -6px 0;
  }
}

.tenant-item {
  margin: 24px 12px 0;
  background: #fff;
  flex: 0 0 auto;
  width: calc(33.3333333% - 24px);

  @media (max-width: 767px) {
    width: calc(50% - 24px);
  }

  @media (max-width: 499px) {
    width: calc(50% - 12px);
    margin: 12px 6px 0;
  }

  @media (max-width: 359px) {
    width: calc(100% - 12px);
  }
}

.tenant-item > a {
    display: block;
    color: inherit !important;
    text-decoration: none !important;
    padding: 15px 20px 30px;
    background: #fff;
    border: 1px solid #e9e9f2;

    &:hover .btn {
        background: lighten($color-teal, 5%);
    }
}

.tenant-item .delta {
  margin: 15px 0 0;
}

.tenant-item__logo {
  width: 130px;
  margin: 0 auto;
}

.tenant-status {
  text-transform: uppercase;
  font-size: 1.1rem;
}

.tenant-status span {
  display: inline-block;
  background-size: 6px 6px;
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 12px;
}

.tenant-status--open span {
  background-image: radial-gradient(#d5df41 3px, transparent 3px)
}

.tenant-status--closed span {
  background-image: radial-gradient($color-red 3px, transparent 3px);
}

.tenant-item .tenant-status {
  margin-bottom: 15px;
}
