.four04 {
    background: url('../img/four04.jpg') center center no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    position: relative;

    @media (min-width: 550px) {
        &::before {
            content: '';
            display: block;
            padding-top: (1035 / 2049) * 100%;
        }
    }
}

.four04 > div {
    @media (min-width: 550px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.four04__cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > * {
        margin-left: 10px;
        margin-right: 10px;

        &:first-child {
            @media (max-width: 549px) {
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
}

.four04 .alfa {
    margin: 0;
}

.four04 .charlie {
    margin: 0.8em 0 1.7em;
}
