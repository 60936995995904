.contact-details.contact-details {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.3;

    dt {
        margin: 20px 0 5px;
    }

    dd {
        font-weight: bold;
        margin: 0;

        .card--dark & {
            color: $color-teal;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        .card--dark & {
            color: $color-teal;
        }
    }
}

.contact-details__col {
    flex: 0 0 auto;
    margin-right: 90px;
}

.contact-form.contact-form {
    margin-top: 20px;
}
