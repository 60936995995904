.share {
  display: inline-flex;
  align-items: center;
  color: $color-manatee;
}

.share h4 {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1;
}

.share ul {
  list-style: none;
  margin: 0 0 0 6px;
  padding: 0;
  display: flex;
}

.share li {
  margin-left: 6px;
}

.share a {
  color: inherit;

  &:hover svg {
    fill: $color-indigo;
  }
}

.share svg {
  fill: $color-bali-hai;
  display: block;
}
