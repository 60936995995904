.newsletter {
  background: #f4f4f8;
  padding-top: 45px;
  padding-bottom: 30px;
}

.newsletter .bravo {
  margin-left: 5px;
  margin-right: 30px;

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
}
