.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-loose {
  letter-spacing: 0.15em;
}

.f-noe {
  font-family: 'Noe Display', serif;
}

.br-left {
  &::before {
    content: '\A';
    white-space: pre;
  }
}

.alfa,
.bravo,
.charlie,
.delta,
.echo,
.foxtrot {
  font-weight: bold;
}

.alfa {
  @extend .f-noe;
  @include font-size(320px, 1400px, 24px, 35px);
  line-height: 1.15;
  margin: 2em 0 1.3em;

  small {
    font-family: $font-family;
    display: block;
    font-size: 1.6rem;
    color: $color-bali-hai;
    margin-bottom: 5px;
  }
}

.bravo {
  @extend .f-noe;
  @include font-size(320px, 1400px, 20px, 25px);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin: 1.3em 0 1em;

  small {
    display: block;
    color: $color-bali-hai;
    font-size: 1.6rem;
  }
}

.charlie {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 2em 0 1em;

  small {
    display: block;
    font-weight: bold;
    color: $color-bali-hai;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
}

.delta {
  font-size: 1.6rem;
  line-height: 1.4;
  margin: 2em 0 1em;

  small {
    display: block;
    color: $color-bali-hai;
    font-size: 1.1rem;
  }
}

.echo {
  font-size: 1.4rem;
  line-height: 1;
  margin: 2em 0 1em;
}

.foxtrot {
  @extend .text-loose;
  font-size: 1.1rem;
  line-height: 1;
  margin: 2em 0 3.2em;

  span {
    display: inline-block;
    border: 2px solid;
    padding: 18px 17px;

    &.alt {
        margin-left: 10px;
        background: #ffffff;
        border-color: $color-bali-hai;
        color: $color-manatee;
    }
  }
}

.foxtrot-with-share {
  display: flex;
  align-items: center;

  @media (min-width: 1200px) {
    padding-right: 0 !important;
  }

  .foxtrot {
    margin: 0 auto 0 0;
  }
}
