.filter-container {
  background: $color-gun-powder;
  color: #fff;
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  @media (max-width: 767px) {
    margin-left: -25px;
    margin-right: -25px;
    padding-right: 40px;
  }

  @media (max-width: 599px) {
    padding-right: 25px;
  }
}

.filter {
  position: relative;
  font-size: 1.4rem;
  margin-left: 20px;
}

.filter button {
  appearance: none;
  color: inherit;
  display: block;
  border-radius: 0;
  width: 100%;
  outline: none;
}

.filter__label {
  position: relative;
  border: 2px solid #fff;
  background: none;
  height: 50px;
  padding: 0 (15px + 42px) 0 15px;
  text-align: right;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 42px;
    width: 2px;
    background: #fff;
  }
}

.filter__label--placeholder {
  @extend .text-loose;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.filter__label svg {
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 16px;
  fill: #fff;
}

.filter__list {
  list-style: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 0;
  margin: 0;
  padding: 13px 0;
  white-space: nowrap;
  background: $color-gun-powder;
  border: 2px solid #fff;
  border-top: 0;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  min-width: 100%;

  @media (min-width: 500px) {
    min-width: 175px;
  }

  .is-active & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.filter__option {
  background: none;
  border: 0;
  padding: 0;
  height: 50px;
  border: 0;
  padding: 0 25px;
  text-align: left;

  &:hover,
  &:focus {
    outline: 0;
    background: $color-dolphin;
  }
}

.filter--year {
    @media (max-width: 499px) {
        display: none;
    }
}

.filter--month {
    @media (max-width: 359px) {
        display: none;
    }
}
