.select {
  position: relative;
  color: $color-text;
}

.select button {
  appearance: none;
  display: block;
  width: 100%;
  text-align: left;
}

.select__label {
  appearance: none;
  background-image: linear-gradient(to left, $color-teal, $color-teal);
  background-size: 60px 60px;
  background-position: right;
  background-color: #fff;
  background-repeat: no-repeat;
  height: 60px;
  border: 0;
  box-shadow: 0 0 0 1px #001d2a;
  border-radius: 0;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  color: inherit;

  &:focus {
    outline: 0;
  }

  .is-active & {
    //background: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    box-shadow: 0 0 0 2px $color-teal;
  }

  .has-error:not(.is-active) & {
      border-color: #f05b4f;
      box-shadow: 0 0 0 1px #f05b4f;
  }
}

.select:not(.is-active) .select__label:focus {
  box-shadow: 0 0 0 2px $color-teal;
  border-color: transparent;
}

.select__label--placeholder {
  color: $color-manatee;
}

.select__icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  margin-top: -10.6775px;
  right: 24px;
  fill: $color-daintree;
}

.select__list {
  list-style: none;
  margin: 0;
  padding: 80px 2px 20px;
  background: #fff;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;

  display: none;

  .is-active & {
    display: block;
    box-shadow: 0 0 0 2px $color-teal;
  }
}

.select__option {
  background: #fff;
  padding: 14px 20px;
  border: 0;
  color: $color-text;

  &:hover,
  &:focus {
    outline: 0;
    background: #f5f5f6;
  }
}



.mobile-select {
  position: relative;
  height: 60px;
  border: 2px solid #d9d9dd;
  margin-bottom: 30px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: svg-load('../icons/chevron-down.svg', fill=$color-gun-powder) center no-repeat;
    background-size: 9.9px 6.36px;
    width: 58px;
    border-left: 2px solid #d9d9dd;
    pointer-events: none;
  }

  @media (max-width: 767px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.mobile-select select {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mobile-select__label {
  line-height: 56px;
  padding: 0 20px;
}
