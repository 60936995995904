.opening-times {
  max-width: 240px;

  &,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  font-size: 1.4rem;
  font-feature-settings: 'tnum';

  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;

  // @supports (display: grid) {
  //   display: grid;
  //   grid-template-columns: 1fr;
  // }
}

.opening-times dt {
  color: $color-teal;
  flex: 0 1 40%;
}

.opening-times dd {
  font-weight: bold;
  flex: 1 0 60%;
  text-align: right;
}

.opening-times {
  dt,
  dd {
    &:nth-child(n + 4) {
      margin-top: 5px;
    }
  }
}
