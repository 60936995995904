html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.nav-open {
    @media only screen and (max-width: 1199px) {
      overflow: hidden;
    }
  }
}

img {
  max-width: 100%;
}

p {
  margin: 0 0 1.2em;
}

.content a:not(.btn) {
    color: $color-manatee;
    text-decoration: underline;

    &:hover {
        color: $color-red;
    }
}

.container {
  max-width: ($container-width + 100px);
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 999px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.main {
  padding-top: 4%;
  padding-bottom: 4%;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

.trim {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

.full-width-image {
  width: 100%;
  height: auto;
}

.anchor {
  position: relative;
  top: -92px;

  @media (max-width: 999px) {
    top: -77px;
  }
}

.m-0 {
  margin: 0 !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-b-0 {
    margin-bottom: 0 !important;
}
.m-b-10 {
    margin-bottom: 10px !important;
}

div[itemprop="openingHoursSpecification"] {
    display: none;
}

iframe.embeded-iframe {
    width: 100%;
    min-height: 500px;
}

.ex-site {
    overflow: hidden;
}

.w-100 {
    width: 100%;
}
