.btn {
  display: inline-block;
  appearance: none;
  border: 0;
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  height: 47px;
  padding: 0 20px;
  white-space: nowrap;
  text-align: center;

  &[disabled] {
      opacity: 0.3;
  }

  @media (max-width: 399px) {
    height: 38px;
    padding: 0 12px;
  }
}

.btn[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.2;
}

a.btn,
span.btn {
  line-height: 47px;

  @media (max-width: 399px) {
    line-height: 37px;
  }
}

.btn--large {
  @extend .text-loose;
  height: 60px;
  padding: 0 42px;
  text-transform: uppercase;
}

a.btn--large {
  line-height: 60px;
}

.btn--indigo {
  background: $color-indigo;

  &:not([disabled]):hover {
    background: lighten($color-indigo, 5%);
  }
}

.btn--teal {
  background: $color-teal;

  &:not([disabled]):hover {
    background: lighten($color-teal, 5%);
  }
}

.btn--with-icon {
  position: relative;
  padding-right: (18px + 16px + 6px);

  svg {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -7px;
    right: 18px;
  }

  &.btn--large {
    padding-right: (42px + 16px + 16px);

    svg {
      right: 42px;
    }
  }

    svg {
      fill: #fff;
    }
}

.text-btn {
  color: $color-manatee;
  font-size: 1.1rem;
  text-decoration: none;

  svg {
    fill: currentColor;
    position: relative;
    top: 0.3em;
    margin-right: 6px;
  }
}
