.content-with-sidebar {
  @media (min-width: 1200px) {
    display: flex;
    align-items: flex-start;
  }
}

.sidebar {
  flex: 0 0 (390 / 1366 * 100%);
  position: relative;
  z-index: 1;

  @media (min-width: 1200px) {
    top: 122px;
    margin-top: -62px;
    margin-bottom: 60px;
  }

  @media (max-width: 767px) {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.sidebar__main {
  @media (min-width: 1200px) {
    background: #fff;
    box-shadow: 0 0 100px 20px rgba(#000, 0.04);
  }
}

.sidebar__heading {
  font-size: 1.8rem;
  margin: 0;
  padding: 24px 42px;
  background: $color-teal;

  @media (max-width: 1199px) {
    display: none;
  }
}

.sidebar__back {
  position: relative;
  background: $color-teal;
  text-decoration: none;
  display: block;
  font-size: 1.1rem;
  line-height: 1;
  margin: 0;
  padding: 24px 42px 24px 62px;
  color: inherit;
  font-size: 1.8rem;
  font-weight: bold;

  @media (max-width: 1199px) {
    padding-left: 45px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 42px;
    margin-top: -6px;
    fill: currentColor;

    @media (max-width: 1199px) {
      left: 25px;
    }
  }

  &:hover {
    background: lighten($color-teal, 5%);
  }
}

.content-with-sidebar .sidebar ~ .content {
  position: relative;
  z-index: 0;
  flex: 0 0 ((976 / 1366 * 100%) + 8.78%);

  @media (min-width: 1200px) {
    margin-left: -8.78%;
  }
}

.content-with-sidebar .content:first-child {
  @media (min-width: 1200px) {
    width: (976 / 1366) * 100%;
    margin-left: auto;
    padding-left: 60px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.sidebar-nav {
  list-style: none;
  margin: 0;
  padding: 14px 0;
  line-height: 1.2;

  @media (max-width: 1199px) {
    display: none;
  }
}

.sidebar-nav a,
.sidebar-nav label {
  color: inherit;
  text-decoration: none;
  display: block;
  position: relative;
  padding: 13px 72px 13px 37px;
  border-left: 5px solid transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 0;
    background: $color-teal;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
}

.sidebar-nav .is-active a,
.sidebar-nav :checked + label {
  background: $color-indigo;
  color: #fff;

  svg {
    fill: #fff;
  }
}

.sidebar-nav svg {
  position: absolute;
  top: 50%;
  right: 42px;
  margin-top: -7px;
  fill: $color-teal;
}

/**
 * Extras
 */
// mobile
.sidebar-extras-container {
  @media (max-width: 1199px) {
    padding-bottom: 4%;
  }
  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}
.sidebar-extras {
  display: none;

  @media (max-width: 1199px) {
    display: block;
  }
}

// desktop
.sidebar .sidebar-extras {
  display: block;

  @media (max-width: 1199px) {
    display: none;
  }
}

.sidebar-extras > .delta {
    color: $color-teal;
}

.sidebar-extras ul {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -15px;
  }
}

.sidebar-extra {
  background: #fff;
  border-top: 4px solid $color-teal;
  box-shadow: 0 0 100px 20px rgba(172, 205, 235, 0.2);
  position: relative;
  padding: 12px 20px 12px 126px;
  margin-top: 15px;
  min-height: 156px;

  @media (max-width: 1199px) {
    flex: 0 0 auto;
    width: calc(50% - 24px);
    margin-left: 12px;
    margin-right: 12px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 24px);
  }
}

.sidebar-extra .delta {
  margin: 0 0 24px;
  font-weight: 400;

  small {
    font-weight: 700;
    margin-bottom: 3px;
  }
}

.sidebar-extra__image {
  position: absolute;
  top: 36px;
  left: 21px;
  width: 80px;
  height: 80px;
  background: #ededf4;
  border-radius: 50%;

  img {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }
}

.sidebar-extra__main h5 {
  font-size: 12px;
}

// stickyfill ghost element that overlaps page heading
.sidebar + *[style] {
  pointer-events: none;
}
