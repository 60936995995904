.quote {
  @extend .trim;
  margin: 40px 0;
  padding: 0 0 0 27px;
  position: relative;

  @media (max-width: 767px) {
    margin: 35px 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    bottom: 5px;
    left: 0;
    width: 3px;
    background: $color-indigo;
  }
}
