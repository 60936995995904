.grid {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 3px -3px;
  color: #fff;

  @media (max-width: 767px) {
    margin: 6px -19px;
  }
}

.grid-item {
  flex: 0 0 auto;
  position: relative;

  &::before {
    content: '';
    display: block;
  }
}

.grid-item--1x1 {
  width: 33.3333333%;

  @media (max-width: 1199px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  &::before {
    padding-top: 100%;
  }
}

.grid-item--1x2 {
  width: 33.3333333%;

  @media (max-width: 1199px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  &::before {
    padding-top: 200%;
  }
}

.grid-item--2x1 {
  width: 66.6666667%;

  @media (max-width: 1199px) {
    width: 100%;
  }

  &::before {
    padding-top: 50%;
  }
}

.grid-item > * {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: $color-teal;

  @media (max-width: 767px) {
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
  }
}

.grid-article {
  padding: (40 / 450) * 100%;

  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &::before {
    right: 0;
    background: rgba(#414142, 0.4);
  }

  &::after {
    width: (520 / 902) * 100%;
    background: $color-indigo;
  }

  &.trim {
      background: no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }
}

.grid-article__top,
.grid-article__main * {
  position: relative;
  z-index: 2;
}

.grid-article__top {
  margin-bottom: auto;
  padding-bottom: 8px;
  border-bottom: 4px solid #fff;
}

.grid-article__image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.grid-article__title {
  width: 80%;
}

.grid-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
