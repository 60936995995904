.form {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -16px 0;
}

.form-col {
  flex: 0 0 auto;
  padding: 0 16px;
  margin-top: 15px;
}

.form-col--100 {
  width: 100%;
}

.form-col--50 {
  width: 50%;

  @media (max-width: 699px) {
    width: 100%;
  }
}

.form-submit {
  flex: 0 0 auto;
  width: 100%;
  margin-top: 15px;
  padding: 0 16px;
  text-align: right;
}

.form-group {
  width: 100%;
  font-size: 1.4rem;
  border: 0;
  padding: 0;
}

.form-group + .form-group {
  margin-top: 15px;
}

.form-group--loose.form-group--loose {
  margin-top: 25px;
  margin-bottom: 25px;
}

%input {
  appearance: none;
  background: #fff;
  border: 0;
  box-shadow: 0 0 0 1px #e9e9f2;
  border-radius: 0;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  color: $color-text;
  display: block;
  outline: none;

  .form--dark & {
    box-shadow: 0 0 0 1px #001d2a;
  }

  &::-webkit-input-placeholder {
    color: $color-bali-hai;
  }

  &::-moz-placeholder {
    color: $color-bali-hai;
  }

  &:-ms-input-placeholder {
    color: $color-bali-hai;
  }

  &:-moz-placeholder {
    color: $color-bali-hai;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $color-teal;
  }

  &.has-error{
      &:focus {
          border-color: $color-red;
          box-shadow: 0 0 0 1px $color-red;
      }
  }

  .form--small & {
    border-radius: 0;
    border: 0;
    height: 57px;
    padding: 0 18px;
  }
}

.form-control {
  &[type="text"],
  &[type="email"] {
    @extend %input;
  }
}

textarea.form-control {
  @extend %input;
  padding: 18px 20px;
  resize: vertical;

  &.form-control--4h {
    height: 285px;

    @media (max-width: 699px) {
      height: 120px;
    }
  }
}

.newsletter .form {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: calc(100% + 10px);
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 999px) {
    max-width: none;
    margin: 0 0 0 -5px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  > * {
    margin: 0 5px 15px;
  }
}

.newsletter .form-group {
  flex: 1 1 auto;
  width: auto;
}

.form-label {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.form-check label {
  display: block;
  position: relative;
  padding-left: 22px;
  font-weight: bold;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    top: 50%;
  }

  &::before {
    background: #fff;
    left: 0;
    margin-top: -5px;
    width: 10px;
    height: 10px;
  }

  &::after {
    background: $color-teal;
    margin-top: -3px;
    left: 2px;
    width: 6px;
    height: 6px;
    opacity: 0;
  }
}

.form-check input:checked + label::after {
  opacity: 1;
}

.form-check + .form-check {
  margin-top: 6px;
}

/**
 * Errors
 */
.form-control.has-error {
  border-color: $color-red;
  box-shadow: 0 0 0 1px $color-red;
}

.form-error {
  color: $color-red;
  font-weight: bold;
  margin: 3px 0 0;
}

.contact-form .form-submit button {
    &.inactive {
        opacity: 0.5;
    }
}

.cf-success {
    padding: 16px;
    background: $color-manatee;
    margin: 10px 0px 0px 0px;
    border-radius: 3px;

    p {
        color: #fff;
        margin: 0px;
        padding: 0px;
        font-weight: bold;
        font-size: 14px;
    }
}

.tenant-remove {
    input {
        color: #fff;
        border: none;
        background: $color-gun-powder + 20;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px 8px;
        border-radius: 3px;
    }
}

.tenant-cat-create {
    @extend .card;

    padding: 20px 20px 60px 180px;

    input {
        display: block;
    }

    input[type="text"] {
        width: calc(100% - 36px);
        padding: 8px;
    }

    input[type="submit"] {
        width: calc(100% - 36px);
        color: #fff;
        border: none;
        background: $color-manatee;
        font-size: 12px;
        text-transform: uppercase;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        transition: all 0.3s ease-out;

        &:hover {
            background: $color-manatee - 20;
        }
    }
}
