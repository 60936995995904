.typeform-widget {
  width: 100%;
  height: 575px;
}

.editable--typeform {
    width: 100%;
}

.typeform-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: all 1s ease-out;

    &.typeform-wrapper--thin {
        padding-bottom: 25%;
    }

    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 1600px) {
    .typeform-wrapper {
        &.typeform-wrapper--thin {
            padding-bottom: 45%;
        }
    }
}

@media (max-width: 768px) {
    .typeform-wrapper {
        &.typeform-wrapper--thin {
            padding-bottom: 85%;
        }
    }
}

@media (max-width: 468px) {
    .typeform-wrapper {
        &.typeform-wrapper--thin {
            padding-bottom: 110%;
        }
    }
}
