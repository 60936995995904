.ex-video {
    position: relative;
    padding-top: (9 / 16) * 100%;
}

.ex-video iframe,
.ex-video .editable--video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
