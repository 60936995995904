.site-footer {
  background: $color-daintree;
  color: #fff;
  text-align: center;
}

.site-footer a {
  text-decoration: none;
  color: inherit;
}

.site-footer__main {
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 1.8rem;

    @media (max-width: 999px) {
        font-size: 1.6rem;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.site-footer__logo {
    display: block;
    width: 164px;
    height: auto;
    margin: 0 auto;
}

.site-footer__contact {
  margin-top: 35px;
  margin-bottom: (85 / 1366 * 100%);

  @media (max-width: 599px) {
    margin-bottom: 35px;
  }

  address {
    font-style: normal;
  }

  dl,
  dt,
  dd {
    margin: 0;
  }

  dt,
  dd {
    display: inline;

    @media (max-width: 399px) {
      display: inline;
    }
  }

  @media (max-width: 499px) {
    dd:first-of-type {
      &::after {
        content: '\A';
        white-space: pre;
      }
    }
  }

  a:hover {
    text-decoration: underline;
  }
}

.site-footer__tel::before,
.site-footer__email::before {
  color: $color-teal;
}

.site-footer__tel::before {
  content: 'T: ';
}

.site-footer__tel + dd::after {
  @media (max-width: 399px) {
    content: '\A';
    white-space: pre;
  }
}

.site-footer__email::before {
  content: 'E: ';

  @media (min-width: 400px) {
    margin-left: 10px;
  }
}

.site-footer__follow ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
}

.site-footer__follow li {
    margin-left: 20px;
    margin-right: 20px;
}

.site-footer__follow a:hover svg {
    opacity: 1;
}

.site-footer__follow svg {
  fill: currentColor;
  opacity: 0.4;
  position: relative;
  top: 0.3em;
  margin-right: 10px;
}

.site-footer__bottom {
  background: $color-indigo;
  font-size: 1.4rem;
  padding-top: 25px;
  padding-bottom: 25px;
}

.site-footer__bottom p {
    margin-bottom: 0.2em;
}

.site-footer__bottom br {
    @media (min-width: 600px) {
        display: none;
    }
}

.site-footer__parallax {
    font-size: 11px;
    font-weight: bold;
    color: $color-teal;
}
