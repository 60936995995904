.floorplan {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: (993.26 / 918.32) * 100%;
  }
}

.floorplan svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.floorplan__pins {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  li {
    position: absolute;
    z-index: 1;
    width: (60 / 950) * 100%;
    margin-bottom: 2%;

    margin-left: (-30 / 950) * 100%;
    margin-top: ((-60 / 950) * 100%) - 2%;

    &:hover {
        z-index: 2;
    }

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      z-index:-1;
      top: 130%;
      left: (5 / 60) * 100%;
      background: rgba(#414152, 0.1);
      width: (50 / 60) * 100%;
      height: (50 / 60) * 100%;
      border-radius: 50%;
      transform: translate(0, -50%) scale(1.4, 0.2);
      transition: transform 0.2s ease-in, opacity 0.2s ease-in;
      opacity: 0;
      pointer-events: none;
    }

    &.is-visible::before {
      opacity: 1;
      transform: translate(0, -50%) scale(1.1, 0.1);
    }
  }
}

.floorplan__pins li:not(.is-visible) {
	pointer-events: none;
}

.floorplan__pins li a {
  position: relative;
	display: block;
  opacity: 0;
  transition: opacity 0.4s;
	background: #fff;
	border-radius: 50%;
    box-shadow: 0 0 0 1px #E2E2E5;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

  &::after {
    content: '';
    display: block;
    position: absolute;
    background: white;
    z-index: -1;
    width: 2px;
    height: 30%;
    top: 100%;
    left: 50%;
    margin-left: -1px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    pointer-events: none;
  }
}
li.is-visible a {
	opacity: 1;
	animation: drop 0.4s forwards;
}

.floorplan__pins img {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 50%;
    border: 3px solid #fff;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
    }
}

.floorplan__pins li.is-visible:nth-child(5n+2) {
	&::before { transition-delay: 0.1s; }
	a { transition-delay: 0.1s; animation-delay: 0.1s; }
}
.floorplan__pins li.is-visible:nth-child(5n+3) {
	&::before { transition-delay: 0.2s; }
	a { transition-delay: 0.2s; animation-delay: 0.2s; }
}
.floorplan__pins li.is-visible:nth-child(5n+4) {
	&::before { transition-delay: 0.3s; }
	a { transition-delay: 0.3s; animation-delay: 0.3s; }
}
.floorplan__pins li.is-visible:nth-child(5n+5) {
	&::before { transition-delay: 0.4s; }
	a { transition-delay: 0.4s; animation-delay: 0.4s; }
}

@mixin drop ($y, $dy) {
	0% {transform: translate(0, $y); animation-timing-function: ease-in;}
	33% {transform: translate(0, ($y+$dy)); animation-timing-function: ease-out;}
	50% {transform: translate(0, ($y - ($y+(0.2*$dy)))); animation-timing-function: ease-in;}
	66% {transform: translate(0, ($y+$dy)); animation-timing-function: ease-out;}
	82% {transform: translate(0, ($y - ($y+(0.05*$dy)))); animation-timing-function: ease-in;}
	92% {transform: translate(0, ($y+$dy)); animation-timing-function: ease-out;}
	97% {transform: translate(0, ($y - ($y+(0.025*$dy)))); animation-timing-function: ease-in;}
	100% { transform: translate(0, ($y+$dy));}
}

@keyframes drop {
	@include drop(-40%, 40%)
}



.floorplan__pins .no-transition-delay {
    &::before,
    a {
        transition-delay: 0s !important;
        animation-delay: 0s !important;
    }
}






$units: (
  (1, 274, 247),
	(2, 244, 400),
	(3, 272, 396),
	(4, 301, 385),
	(5, 322, 377),
	(6, 342, 377),
	(7, 414, 308),
	(8, 401, 233),
	(9, 387, 245),
	(10, 440, 230),
	(11, 431, 212),
	(12, 457, 175),
	(13, 394, 359),
	(14, 379, 382),
	(15, 471, 231),
	(16, 502, 198),
	(17, 527, 212),
	(18, 537, 232),
	(19, 551, 280),
	(20, 590, 223),
	(21, 618, 310),
	(22, 689, 255),
	(23, 732, 263),
	(24, 799, 307),
	(25, 629, 433),
	(26, 673, 444),
	(27, 612, 482),
	(28, 595, 500),
	(29, 605, 534),
	(30, 578, 538),
	(31, 465, 251),
	(32, 476, 270),
	(33, 488, 288),
	(34, 476, 336),
	(35, 536, 416),
	(36, 446, 424),
	(37, 448, 461),
	(38, 467, 471),
	(39, 460, 488),
	(40, 449, 507),
	(41, 485, 524),
	(42, 529, 586),
	(43, 586, 613),
	(44, 481, 617),
	(45, 495, 620),
	(46, 498, 617),
	(47, 543, 621),
	(48, 556, 620),
	(49, 281, 441),
	(50, 334, 409),
	(51, 356, 420),
	(52, 304, 454),
	(53, 358, 477),
	(54, 287, 536),
	(55, 322, 541),
	(56, 349, 550),
	(57, 384, 574),
	(58, 412, 599),
	(59, 397, 620),
	(60, 384, 682),
	(61, 472, 679),
	(62, 509, 666),
	(63, 524, 686),
	(64, 549, 674),
	(65, 593, 683),
	(66, 587, 658),
	(67, 23, 655),
	(68, 59, 675),
	(69, 99, 696),
	(70, 91, 744),
	(71, 71, 788),
	(72, 139, 812),
	(73, 190, 800),
	(74, 93, 880),
	(75, 171, 894),
	(76, 243, 869),
	(77, 267, 882),
	(78, 293, 893),
	(79, 309, 907),
	(80, 328, 874),
	(81, 367, 901),
	(82, 851, 714),
	(83, 742, 741),
	(84, 771, 751),
	(85, 771, 782),
	(86, 788, 794),
	(87, 769, 819),
	(88, 795, 831),
	(89, 818, 909),
    (90, 351, 871),
    (91, 652, 285),
    (92, 521, 615)
);

@each $i, $x, $y in $units {
	.unit-#{$i} {
		left: ($x / 918) * 100%;
		top: ($y / 993) * 100%;
	}
}
