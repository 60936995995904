.tenant-hero {
  @media (min-width: 768px) {
    display: flex;
  }
}

.tenant-hero__slider {
    flex: 0 0 auto;

    display: flex;
    overflow: hidden;
    position: relative;

    &.flickity-enabled {
        overflow: visible;
    }

    @media (min-width: 768px) {
        width: calc(100% - 300px);
    }

    &::before {
        @media (max-width: 767px) {
        content: '';
        display: block;
        padding-top: (500 / 1096) * 100%;
        }
    }
}

.tenant-hero__slider--video > * {
  width: 100%;
}

.tenant-hero__image {
  background-color: $color-indigo;
  background-size: cover;
  background-position: 50% 50%;

  flex: 0 0 auto;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
  }
}

.tenant-hero__slider .flickity-viewport {
    height: 100% !important;
    @media (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.tenant-hero__times {
  background: $color-daintree;
  color: #fff;
  flex: 0 0 auto;
  padding: 86px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;

  @media (max-width: 1299px) {
      padding-top: 60px;
      padding-bottom: 60px;
  }

  @media (min-width: 768px) {
    width: 300px;
  }

  @media (max-width: 767px) {
    margin-left: -5px;
    padding: 30px 25px;
  }
}

.opening-times--all {
    font-size: 12px;
    margin-top: 40px;

    .toggle {
        background: $color-gun-powder - 30;
        padding: 4px;
        display: block;
        margin-top: 10px;
        text-align: center;

        .editable--select {
            display: block;
        }
    }
}
