.media-list {
  list-style: none;
  margin: -24px -12px 0;
  padding: 0;
  font-size: 1.4rem;

  display: flex;
  flex-wrap: wrap;
}

.media-item {
  flex: 0 0 auto;
  margin: 24px 12px 0;
  background: #fff;
  border: 1px solid #e9e9f2;

  .media-list--1 & {
    width: calc(100% - 24px);
  }

  .media-list--2 & {
    width: calc(50% - 24px);

    @media (max-width: 767px) {
      width: calc(100% - 24px);
    }
  }

  .media-list--3 & {
    width: calc(33.3333333% - 24px);

    @media (max-width: 1199px) {
      width: calc(50% - 24px);
    }

    @media (max-width: 767px) {
      width: calc(100% - 24px);
    }
  }
}

.media-item__main {
  display: flex;
  padding: 35px 35px 35px 20px;
  border-top: 4px solid $color-teal;
}

.media-item__logo {
  flex: 0 0 auto;
  margin-right: 25px;
  width: 28%;
  max-width: 80px;

  img {
    display: block;
  }
}

.media-item__text {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;

  > * {
    width: 100%;
  }
  .delta a {
    color: $color-text;
    text-decoration: none;
  }
}

.media-item .btn {
  margin-top: auto;
}

.media-item__pre {
  display: block;
  order: -1;
  font-size: 1.1rem;
  font-weight: bold;
  color: $color-manatee;

  &.non-event {
    margin-bottom: 20px;
  }

  svg {
    fill: #d9d9dd;
    position: relative;
    top: 0.3em;
    margin-right: 6px;
  }
}

.media-item--img-overlay {
  .img {
    img {
      opacity: 0.6;
      z-index: 1;
      transition: opacity 0.3s, transform 0.3s ease-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #73b8e1;
    }
  }

  &:hover .img img {
    opacity: 1;
    transform: scale(1.1);
  }
}
