.slider {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  background: $color-indigo;

  @media (max-width: 767px) {
    width: calc(100% + 26px);
    margin-left: -13px;
  }
}

.article .slider {
    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
    }
}

.slider-item {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
}

.slider-item__image {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: (500 / 1366) * 100%;
  }
}

.slider-item__image--home {
    &::before {
        padding-top: (660 / 1366) * 100%;
    }
}

.slider-item img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider > .slider-info {
  position: absolute;
  z-index: 10;
  top: 60px;
  left: 60px;
  bottom: 60px;
  width: 30%;
  color: #fff;
  opacity: 0;
  transition: opacity 0.4s;

  .article & {
      top: 10px;
      left: 10px;
      bottom: 10px;
      width: 40%;
  }

  &.is-visible {
      opacity: 1;
  }

  @media (max-width: 1279px) {
    top: 0;
    left: 0;
    width: 35%;
  }

  @media (max-width: 1099px) {
      width: 45%;
  }

  @media (max-width: 899px) {
      width: 50%;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.slider > .slider-info .slider-info__content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: rgba($color-indigo, 0.85);
  padding: 40px;
  transition: opacity 0.3s;
  overflow: hidden;

  @media (max-width: 899px) {
    padding: 30px;
  }

  .article & {
      padding: 25px;
  }
}

.slider > .slider-info .slider-info__content:not(.is-active) {
  opacity: 0;
  pointer-events: none;
}

.slider-info .alfa {
  @include font-size(320px, 1400px, 24px, 40px);
  margin-bottom: 0.5em;

  .article & {
      display: none;
  }
}

.slider-item > .slider-info {
  color: #fff;
  padding: 20px 20px (10px + 50px + 25px);

  .slider.flickity-disabled & {
    padding-bottom: 20px;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.slider-nav {
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  opacity: 0;

  .slider.flickity-enabled & {
    opacity: 1;
  }

  @media (max-width: 1199px) {
    width: 50px;
    height: 50px;
    bottom: 10px;
  }

  svg {
    display: block;
    margin: auto;

    @media (max-width: 1199px) {
      width: (22.63px / 2);
      height: (41.01px / 2);
    }
  }
}

.slider-nav--prev {
  right: 100px;
  padding-right: 7px;

  @media (max-width: 1199px) {
    right: 50px;
  }

  svg {
    fill: #fff;
  }
}

.slider-nav--next {
  right: 0;
  background: #fff;
  padding-left: 7px;

  svg {
    fill: $color-gun-powder;
  }
}

/**
 * Mobile adaptive height
 */
.slider {
  @media (max-width: 767px) {
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        padding-top: (500 / 1366) * 100%;
        width: 100%;
    }

    &.flickity-enabled {
        &::before {
            content: none;
        }
    }
  }
}

.slider--home {
    @media (max-width: 767px) {
        &::before {
            padding-top: (660 / 1366) * 100%;
        }
    }
}

.slider:not(.flickity-enabled):not(.flickity-disabled) .slider-item {
  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
