.card {
  position: relative;

  @media (max-width: 767px) {
    margin-left: -25px;
    margin-right: -25px;
  }

  &.card--editable{
    .editable{
      display: block;
    }
  }
}

.card--dark {
    background: $color-daintree;
    color: #fff;
}

.card--full {
  padding: 60px 0;

  @media (max-width: 999px) {
    padding: 40px;
  }

  @media (max-width: 599px) {
    padding: 20px 25px;
  }
}

.card--full > .img:first-child {
    margin: -60px 0 60px -60px;
    width: calc(100% + 120px);

    @media (max-width: 999px) {
        margin: -40px 0 40px -40px;
        width: calc(100% + 80px);
    }

    @media (max-width: 599px) {
        padding: 20px 25px 20px 20px;
        margin: -20px 0 20px -20px;
        width: calc(100% + 45px);
    }
}

.card--with-panel {
  position: relative;

  &.card--with-overlay {
      width: 100%;
      height: 256px;
  }
}

.card__main,
.card--with-panel h2 {
  position: relative;
  padding: 60px;

  @media (min-width: 1200px) {
    padding-left: calc(10.975% + 60px);
  }

  @media (max-width: 999px) {
    padding: 40px;
  }

  @media (max-width: 599px) {
    padding: 30px 25px;
  }
}

.card--with-panel h2 {
  color: #fff;
  position: absolute;
  top: 0;
  z-index: 2;

  @media (max-width: 599px) {
    top: 14%;
  }
}

.card:first-child .card__main {
    @media (min-width: 1200px) {
        padding-top: 90px;
    }
}

.card--with-panel .card__main {
  @media (min-width: 768px) {
    padding-right: (60px + 300px + 50px);
  }

  @media (min-width: 768px) and (max-width: 999px) {
    padding-right: (40px + 300px + 50px);
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
  }
}

.card--with-overlay {
  &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(65, 65, 84, .35);
      z-index: 1;
  }
}

.card__main > div {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.card + .card,
.card--grey {
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    background: #f7f7fa;
    top: 0;
    bottom: 0;
    left: -2000px;
    right: -2000px;
  }
}

.card__panel {
  padding: 36px 40px;

  @media (max-width: 999px) {
    top: 40px;
    right: 40px;
    padding: 28px 30px;
  }

  @media (min-width: 767px) {
    position: absolute;
    z-index: 10;
    top: 60px;
    right: 60px;
    width: 300px;
  }

  @media (max-width: 767px) {
    margin-left: -40px;
    margin-right: -40px;
  }

  @media (max-width: 599px) {
    margin-left: -25px;
    margin-right: -20px;
    padding: 25px;
  }
}

.card__panel--high {
  top: -60px;
}

.card__panel--gun-powder {
  background: $color-gun-powder;
  color: #fff;
}

.card__panel--red {
  background: $color-red;
  color: #fff;
}

.card__image {
  @media (min-width: 700px) {
    float: right;
    width: 300px;
    margin-left: 50px;
    margin-bottom: 40px;
  }

  @media (max-width: 699px) {
    width: 100%;
  }
}

.card__more {
  margin-top: 60px;

  @media (max-width: 999px) {
    margin-top: 40px;
  }

  @media (max-width: 599px) {
    margin-top: 20px;
  }
}

.card + .bravo {
  margin: 2.8em 0 1.4em;

  @media (min-width: 1200px) {
    padding-left: calc(10.975% + 60px);
  }
}

.card__hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border: 0;
    margin: (100 / 856 * 100%) 0 (50 / 856 * 100%);

    .card--dark & {
        border-top: 1px solid #143a45;
    }
}
