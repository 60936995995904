@mixin font-size($min-width, $max-width, $min-size, $max-size, $show-max: true) {
  $min-width-value: ($max-size - $min-size) / ($max-width - $min-width);
  $vw-value: (($max-size - $min-size) / ($max-width - $min-width) * 100) + vw;

  @media (min-width: $min-width) {
    font-size: $max-size - ($min-size / 2); // fallback
    font-size: calc((#{$min-size} - #{$min-width} * #{$min-width-value}) + #{$vw-value});
  }

  @if $show-max {
    @media (min-width: $max-width) {
      font-size: $max-size;
    }
  }
}

@mixin nav-open () {
    @media only screen {
        .nav-open & {
            @content;
        }
    }
}
