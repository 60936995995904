.tenant-info {
  @media (min-width: 700px) and (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin-bottom: 35px;
  }
}

.tenant-info__header {
  @media (min-width: 700px) and (max-width: 1199px) {
    flex: 0 0 50%;

    display: flex;
    flex-direction: column;
  }
}

.tenant-info__heading {
  margin: 0;
  padding: 15px;
  text-align: center;

  @media (min-width: 700px) and (max-width: 1199px) {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

.tenant-info__status {
  text-align: center;
  padding: 18px 0;
  border-top: 1px solid #e9e9f2;
  border-bottom: 1px solid #e9e9f2;

  .tenant-status {
    text-transform: none;
    font-weight: bold;
    color: $color-bali-hai;
    font-size: 1.6rem;
  }
}

.tenant-info__main {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 30px 42px;
  margin: 0;

  @media (min-width: 700px) and (max-width: 1199px) {
    flex: 0 0 50%;
  }

  @media (max-width: 699px) {
    padding: 30px 25px;
  }
}

.tenant-info__main a {
  color: inherit;
  text-decoration: none;

  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.tenant-info__main dt {
  font-size: 1.1rem;
  color: $color-bali-hai;
  margin: 0;
  padding: 0;
}

.tenant-info__main dd {
  margin: 0;
  margin-right: auto;
  padding: 0;
}

.tenant-info__main svg {
  fill: $color-teal;
  position: relative;
  top: 0.4em;
  margin-left: 6px;
}

.tenant-info__map {
    width: 100%;
    height: 190px;
    background: $color-indigo;
}

.tenant-directions {
    position: relative;
}

.tenant-directions__title {
    margin: 0;

    button {
        appearance: none;
        background: $color-daintree;
        color: #fff;
        position: relative;
        display: block;
        width: 100%;
        height: 60px;
        padding: 0 21px;
        border: 0;
        text-align: left;
        font-weight: bold;
        font-size: 1.4rem;
        cursor: pointer;
        border-radius: 0;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 60px;
            background: $color-teal;
        }

        svg {
            position: absolute;
            display: block;
            top: (30px - 8px);
            right: (30px - 8px);
            fill: #fff;

            .tenant-directions.is-active & {
                transform: rotate(180deg);
            }
        }
    }
}

.tenant-directions__main {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    background: #143a45;
    color: #fff;
    padding: 35px;
    display: none;

    .tenant-directions.is-active & {
        display: block;
    }
}
