$font-family: 'Larsseit', Helvetica, Arial, sans-serif;
$font-size: 1.6rem;
$line-height: 1.625;
$container-width: 1366px;

$color-gun-powder: #414154;
$color-dolphin: #676777;
$color-manatee: #8d8d99;
$color-spindle: #accdeb;
$color-red: #f05b4f;
$color-red-light: #f37b72;
$color-red-lighter: #f69c95;

// Arena Quarter
$color-teal: #60d3d9;
$color-indigo: #5252cb;
$color-daintree: #012a36;
$color-bali-hai: #90a3b4;

$color-background: #fff;
$color-text: $color-daintree;

// Breakpoints
$mobile-nav: 800px;
