.blockquote-with-image {
  @media (min-width: 600px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  margin: 70px 0;
  @media (max-width: 767px) {
    margin: 35px 0;
  }
}
.blockquote-with-image__blockquote {
  @extend .trim;
  flex: 0 0 auto;
  position: relative;
  font-size: 1.4rem;
  font-weight: bold;
  color: $color-bali-hai;
  @media (max-width: 599px) {
    margin: 35px 0 0;
  }
  @media (min-width: 600px) {
    width: 50%;
    top: -7px;
  }
}
.blockquote-with-image__image {
  flex: 0 0 auto;
  @media (min-width: 600px) {
    width: calc(50% - 30px);
  }
  @media (min-width: 768px) {
    width: calc(50% - 60px);
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .blockquote-with-image--swap & {
    order: 1;
  }
}
