.page-heading {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.page-heading h1 {
  font-size: inherit;
  margin: 0 auto 0 0;
}

.page-heading div.is-admin, .page-heading p {
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: normal;
  color: $color-teal;

  @media (max-width: 599px) {
    flex: 0 0 100%;
    margin-top: 10px;
  }
}
