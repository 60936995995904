.info {
  padding: 40px;

  @media (max-width: 999px) {
    padding: 30px;
  }
}

.info--white {
  background: #fff;
}

.info p {
  max-width: 700px;
}

.info .img {
  margin-left: 14%;
  margin-bottom: 30px;
  float: right;
  width: 150px;
}

.info .delta {
  position: relative;
  padding-bottom: 1em;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 20px;
    height: 2px;
    background: $color-red;
  }
}
