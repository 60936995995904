.first-direct-list {
    display: flex;
    flex-wrap: wrap;
    margin: -24px -12px 0;
}

.first-direct-item {
    flex: 0 0 auto;
    width: calc(33.3333333% - 24px);
    margin: 24px 12px 0;
    background: $color-daintree;
    color: #fff;

    display: flex;
    flex-direction: column;

    @media (max-width: 1269px) {
        width: calc(50% - 24px);
    }

    @media (max-width: 767px) {
        width: calc(100% - 24px);
    }
}

.first-direct-item__image {
    display: block;
    position: relative;
    background: $color-indigo;

    &::before {
        content: '';
        display: block;
        padding-top: (386 / 876) * 100%;
    }

    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.first-direct-item__main {
    display: flex;
    flex-direction: column;
    padding: 0 30px 30px;
    position: relative;
    flex: 1 0 auto;

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 100px;
        background: ease-out-sine-gradient(to bottom, rgba($color-daintree, 0%), $color-daintree);
        pointer-events: none;
    }

    > a {
        display: block;
        text-decoration: none;
        color: inherit;
    }
}

.first-direct-item__meta {
    margin: 10px 0 30px;
    line-height: 1.3;

    @media (min-width: 860px) {
        padding-left: 110px;
    }

    dd {
        margin: 0;
    }

    dt {
        position: relative;
        top: 0.1em;
        margin: 0 8px 0 0;
    }

    > div:not(.first-direct-item__date) {
        display: flex;
        align-items: flex-start;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    svg {
        fill: $color-bali-hai;
    }
}

.first-direct-item__title {
    color: $color-teal;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 1.2;
    margin: 10px 0 0;

    @media (min-width: 860px) {
        padding-left: 110px;
    }
}

.first-direct-item__time dd {
    font-weight: bold;
}

.first-direct-item__date {
    @media (max-width: 859px) {
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
    }

    @media (min-width: 860px) {
        position: absolute;
        top: 0;
        left: 30px;

        svg {
            display: none;
        }
    }
}

.first-direct-item__parking {
    margin-top: auto;
}

.first-direct-view__header {
    position: relative;
}

.first-direct-view__title {
    margin-top: 0;
    margin-bottom: 5px;
}

.first-direct-view__meta {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    color: $color-bali-hai;
    line-height: 1.3;

    dd {
        margin: 0;
    }

    > div:not(.first-direct-view__date) {
        display: flex;
        margin-top: 10px;
        margin-right: 30px;

        dt {
            position: relative;
            top: 0.1em;
            margin: 0 8px 0 0;
        }
    }

    svg {
        fill: $color-teal;
    }
}

.first-direct-view__location {
    flex: 1 0 300px;
}

.first-direct-view__date {
    color: $color-text;

    @media (max-width: 859px) {
        display: flex;
        margin-top: 10px;
        margin-right: 30px;
        color: $color-bali-hai;
        white-space: nowrap;

        dt {
            position: relative;
            top: 0.1em;
            margin: 0 8px 0 0;
        }
    }

    @media (min-width: 860px) {
        position: absolute;
        top: 0;
        right: 0;

        svg {
            display: none;
        }
    }
}

.first-direct-view__time {
    font-weight: bold;
}

.first-direct-view__main {
    margin-top: 35px;
}

.parking-location {
    padding: 30px 40px;
}

.parking-location__address {
    position: relative;
    padding-left: 25px;
    margin-bottom: 35px;

    svg {
        position: absolute;
        top: 4px;
        left: 0;
        fill: $color-teal;
    }
}
