.article {
  @extend .trim;
  position: relative;
  // padding-left: (130px + 50px);
  padding-left: calc(10.975% + 60px);
  padding-right: 60px;
  margin-top: 70px;

  @media (max-width: 1199px) {
    padding-left: 60px;
  }

  @media (max-width: 767px) {
    padding: 0;
    margin-top: 40px;
  }

  > p:first-of-type {
    margin-top: 70px;
  }
}

.foxtrot-with-share + .article {
  margin-top: 35px;
}

.article__header {
  display: flex;
  flex-direction: column;
}

.article__title {
  margin-top: 0.2em;
}

.article__date {
  order: -1;
  color: $color-manatee;
  font-weight: bold;

  svg {
    fill: currentColor;
    position: relative;
    top: 0.1em;
    margin-right: 3px;
  }
}

.article__logo {
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1199px) {
    display: none;
  }
}

.article > img:not([class]), .img-full img {
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: none;

  @media (min-width: 1200px) {
      width: calc(100% + 60px);
  }
  @media (max-width: 767px) {
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.article a {
    color: $color-manatee;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.article .ex-section--video, .ex-section--video {
    margin-top: 50px;
    margin-bottom: 50px;

    @media (min-width: 1200px) {
        width: calc(100% + 60px);
    }
    @media (max-width: 767px) {
        width: calc(100% + 50px);
        margin-left: -25px;
        margin-top: 35px;
        margin-bottom: 35px;
    }
}

/**
 * Table
 */
.article-table-container {
    @media (max-width: 767px) {
        overflow: auto;
        margin-right: -25px;
        margin-left: -25px;
        padding-left: 25px;

        table {
            width: 768px;
        }
    }
}
.article table {
    border-collapse: collapse;
    margin-top: 70px;
    margin-bottom: 70px;

    @media (max-width: 767px) {
        margin-top: 35px;
        margin-bottom: 35px;
        border-right: 25px solid #fff;
    }

    thead {
        border-bottom: 2px solid $color-manatee;
        line-height: 1.2;

        th {
            padding-bottom: 15px;
            vertical-align: bottom;
        }
    }

    tbody tr:not(:first-child) td {
        border-top: 1px solid rgba(#000, 0.1);
    }

    th,
    td {
        vertical-align: top;
        text-align: left;
        padding: 0;

        &:not(:first-child) {
            padding-left: 30px;
        }
    }

    tbody td {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
