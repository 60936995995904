@media (min-width: 860px) {
    .date {
        border: 1px solid $color-teal;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        margin: 0;

        dt,
        dd {
            margin: 0;
        }

        time span {
            display: block;
            padding: 6px 8px;
        }

        time span:first-child {
            font-size: 3.5rem;
            border-bottom: 1px solid $color-teal;
            padding: 4px 8px;
        }
    }
}
